import React from "react";
import ProductCard from "../product-card";
import ProductsRowsPropsTypes from "./types";

export default function ProductsRows({ products, className, columnClassName: itemClassName = "col-xl-3 col-lg-4 col-sm-6" }: ProductsRowsPropsTypes) {

  return (
    <div className={`row ${className || ""}`}>
      {
        products.map(({ node: product }) => (
          <div
            className={itemClassName}
            key={`trending-product-${product.slug}-${product.id}`}
          >
            <ProductCard
              name={product?.name}
              wordpress_id={product?.wordpress_id}
              link={`/product/${product?.slug}/`}
              price={product?.regular_price || 0}
              original_price={product?.price || undefined}
              slug={product?.slug || "#"}
              description={product?.description || ""}
              imageSrc={product.images?.[0]?.src || ""}
              imageHeight={product.images?.[0]?.height}
              imageWidth={product?.images?.[0]?.width}
            />
          </div>
        ))
      }
    </div>
  );
}
