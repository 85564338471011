import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useReducer, useRef } from "react";
import { constructCartDataByProduct } from "../../helpers/cart";
import { objectifyFormData } from "../../helpers/form";
import httpRequest from "../../helpers/http";
import { globalReducer } from "../../helpers/reducers";
import CartStorage from "../../helpers/storage";
import { validateOrderLineItem } from "../../helpers/validator/cart";
import { ProductType } from "../../types/global";
import { LineItemType } from "../templates/product/types";

const initialState = {
  loading: false,
  errors: null,
  data: {
    quantity: 1
  },
  message: null
};
export default function Modal() {
  const { storeDetails: { product_stock_url } } = useStaticQuery(graphql`
    {
      storeDetails: zzStoreWebsiteDetails {
        product_stock_url
      }
    }
  `);
  const [state, dispatch] = useReducer(globalReducer, initialState);
  const productDataRef = useRef<ProductType>(null!);
  const alertType = state.message !== null ? "success" : "danger";
  const onEditQuantity = (newQuantity: number) => () => {
    if (newQuantity >= 1) {
      dispatch({
        type: "SET_FIELD",
        payload: {
          key: "quantity",
          value: newQuantity
        }
      });
    }
  }
  const onAddToCart = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch({
      type: "LOADING",
      payload: true
    });
    const form = event.currentTarget;
    const formData = new FormData(form);
    const orderData = objectifyFormData(formData);
    const lineItem = constructCartDataByProduct(orderData);
    const errors = validateOrderLineItem(lineItem, []);

    if (errors !== false) {
      return dispatch({
        type: "ERROR",
        payload: errors
      });
    }
    else if (productDataRef.current) {
      const response = await httpRequest({
        url: `${product_stock_url}/${orderData.product_id}/availability?quantity=${orderData.quantity}`,
        requestConfig: {
          method: "GET",
        }
      });

      if (response?.code !== "success") {
        return dispatch({
          type: "ERROR",
          payload: response.errors
        });
      }

      const cart = new CartStorage();
      const productDetails = {
        id: String(productDataRef.current.wordpress_id),
        slug: productDataRef.current.slug,
        name: productDataRef.current.name,
        regular_price: productDataRef.current.regular_price,
        thumbnail: productDataRef.current.thumbnail,
      }
      await Promise.all([
        cart.addItem(lineItem as LineItemType),
        cart.pushItemDetails(productDetails)
      ]);
      dispatch({
        type: "SUCCESS",
        payload: "Ajouter au panier avec succes",
      })
    }
  }

  useEffect(() => {
    const modal = document.getElementById('productView');
    modal?.addEventListener('show.bs.modal', openModal);
    modal?.addEventListener('hide.bs.modal', resetModal);
    function openModal(event: any) {
      // Button that triggered the modal
      const button = event.relatedTarget;
      const recipient = button.getAttribute('data-bs-product')
      try {
        let productData: ProductType = JSON.parse(recipient);
        productDataRef.current = productData;
        const productId = modal?.querySelector(".js-modal-product-id");
        const productName = modal?.querySelector('.js-modal-product-name');
        const productImage = modal?.querySelector('.js-modal-product-image');
        const productDescription = modal?.querySelector('.js-modal-product-description');
        const productPrice = modal?.querySelector('.js-modal-product-price');
        if (productName !== null && productName !== undefined) {
          productName.textContent = productData.name;
        }
        if (productImage !== null && productImage !== undefined) {
          productImage.setAttribute("style", `background-image: url(${String(productData?.thumbnail?.src || "")})`);
          productImage.setAttribute("href", `/product/${productData?.slug}`);
        }
        if (productPrice !== null && productPrice !== undefined) {
          productPrice.textContent = `${productData.regular_price || ""}DZD`;
        }
        if (productDescription !== null && productDescription !== undefined) {
          productDescription.innerHTML = String(productData.description || "");
        }
        if (productId !== null && productId !== undefined) {
          productId?.setAttribute("value", String(productData.wordpress_id || ""));
        }
      } catch (error) { }
    }
    function resetModal() {
      dispatch({
        type: "RESET_STATE",
        payload: initialState
      });
    }

    return () => {
      modal?.removeEventListener('show.bs.modal', openModal);
      modal?.removeEventListener('hide.bs.modal', resetModal);
    }
  }, []);

  return (
    <div className="modal fade" id="productView" tabIndex={-1}>
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content overflow-hidden border-0">
          <button className="btn-close p-4 position-absolute top-0 end-0 z-index-20 shadow-0" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
          <div className="modal-body p-0">
            <div className="row align-items-stretch">
              <div className="col-lg-6 p-lg-0">
                <a
                  className="product-view d-block h-100 bg-cover bg-center js-modal-product-image"
                  href={`#`}
                />
              </div>
              <div className="col-lg-6">
                <div className="p-4 my-md-4">
                  <h2 className="h4 js-modal-product-name"></h2>
                  <p className="text-muted js-modal-product-price"></p>
                  <p className="text-sm mb-4 overflow-hidden js-modal-product-description"></p>
                  <form onSubmit={onAddToCart} className="row align-items-stretch mb-4 gx-0">
                    <input
                      name="product_id"
                      type="hidden"
                      className="js-modal-product-id"
                    />
                    {
                      (!!state.message || !!state.errors?.global) && (
                        <div
                          className={`alert alert-${alertType} rounded-0`}
                          role="alert"
                        >
                          {state.message || state.errors?.global}
                        </div>
                      )
                    }
                    <div className="col-sm-7">
                      <div className="border d-flex align-items-center justify-content-between py-1 px-3"><span className="small text-uppercase text-gray mr-4 no-select">Quantité</span>
                        <div className="quantity">
                          <button
                            className="dec-btn p-0"
                            type="button"
                            onClick={onEditQuantity(state.data.quantity - 1)}
                            disabled={state.loading}
                          >
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 192 512" height="1rem" width="1rem" xmlns="http://www.w3.org/2000/svg">
                              <path d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z" />
                            </svg>
                          </button>
                          <input
                            className="form-control border-0 shadow-0 p-0"
                            value={state.data.quantity}
                            name={"quantity"}
                            readOnly
                          />
                          <button
                            className="inc-btn p-0"
                            type="button"
                            onClick={onEditQuantity(state.data.quantity + 1)}
                            disabled={state.loading}
                          >
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 192 512" height="1rem" width="1rem" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-5">
                      <button
                        className="btn btn-dark btn-sm w-100 h-100 d-flex align-items-center justify-content-center px-0"
                        type="submit"
                        disabled={state.loading}
                      >
                        Ajouter au panier
                      </button>
                    </div>
                    {
                      state.errors?.quantity && (
                        <span style={{ color: "#9a1111" }}>{state.errors?.quantity}</span>
                      )
                    }
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
